<template>
  <div id="home" v-if="homeBlocks">
    <HeroSlider
      v-if="homeBlocks.heroSlider.active"
      :block-data="homeBlocks.heroSlider.data"
    />
    <HorizontalCard
      v-if="homeBlocks.horizontalCard.active"
      :block-data="homeBlocks.horizontalCard.data"
    />
    <CategoryProductsCarousel
      v-if="productsCarouselsData.first && productsCarouselsData.first.active"
      :carousel-category-id="productsCarouselsData.first.category_uid"
      :title="productsCarouselsData.first.title"
    />
    <Tiles v-if="homeBlocks.tiles.active" :block-data="homeBlocks.tiles.data" />
    <CategoryProductsCarousel
      v-if="productsCarouselsData.second && productsCarouselsData.second.active"
      :carousel-category-id="productsCarouselsData.second.category_uid"
      :title="productsCarouselsData.second.title"
    />
  </div>
</template>

<script type="module">
import {
  defineComponent,
  useFetch,
  onMounted,
  useMeta,
  ref,
} from '@nuxtjs/composition-api';
import { useGtm, useHome } from '~/composables';
import { useCache, CacheTagPrefix } from '@gemini/vsf-cache';
import HeroSlider from '~/components/Homepage/HeroSlider.vue';
import CategoryProductsCarousel from '~/components/General/CategoryProductsCarousel.vue';
import HorizontalCard from '~/components/CMS/HorizontalCard.vue';
import Tiles from '~/components/CMS/Tiles.vue';
import { SfLoader } from '@storefront-ui/vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    Tiles,
    HorizontalCard,
    CategoryProductsCarousel,
    HeroSlider,
  },
  head: {},
  setup() {
    const { pageViewPush } = useGtm();
    const { addTags } = useCache();
    const { loadHomeBlocks, populateHomeMeta, homeBlocks, homeMeta } =
      useHome();
    const productsCarouselsData = ref({});

    useFetch(async () => {
      await loadHomeBlocks();
      productsCarouselsData.value = {
        first: {
          ...(homeBlocks.value.productsCarousels?.data?.first && {
            ...homeBlocks.value.productsCarousels.data.first,
          }),
        },
        second: {
          ...(homeBlocks.value.productsCarousels?.data?.second && {
            ...homeBlocks.value.productsCarousels.data.second,
          }),
        },
      };
      await populateHomeMeta();
      if (
        typeof homeBlocks.value === 'object' &&
        !Array.isArray(homeBlocks.value) &&
        Object.keys(homeBlocks.value).length > 0
      ) {
        addTags([{ prefix: CacheTagPrefix.View, value: 'page' }]);
      }
    });

    onMounted(() => {
      pageViewPush('HomePage');
    });

    useMeta(() => homeMeta.value);

    return { homeBlocks, productsCarouselsData };
  },
});
</script>
